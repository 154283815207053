exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-bootfitting-jsx": () => import("./../../../src/pages/bootfitting.jsx" /* webpackChunkName: "component---src-pages-bootfitting-jsx" */),
  "component---src-pages-en-bootfitting-jsx": () => import("./../../../src/pages/en/bootfitting.jsx" /* webpackChunkName: "component---src-pages-en-bootfitting-jsx" */),
  "component---src-pages-en-index-jsx": () => import("./../../../src/pages/en/index.jsx" /* webpackChunkName: "component---src-pages-en-index-jsx" */),
  "component---src-pages-en-mojo-jsx": () => import("./../../../src/pages/en/mojo.jsx" /* webpackChunkName: "component---src-pages-en-mojo-jsx" */),
  "component---src-pages-en-services-jsx": () => import("./../../../src/pages/en/services.jsx" /* webpackChunkName: "component---src-pages-en-services-jsx" */),
  "component---src-pages-home-jsx": () => import("./../../../src/pages/home.jsx" /* webpackChunkName: "component---src-pages-home-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-jsx": () => import("./../../../src/pages/legal.jsx" /* webpackChunkName: "component---src-pages-legal-jsx" */),
  "component---src-pages-mojo-jsx": () => import("./../../../src/pages/mojo.jsx" /* webpackChunkName: "component---src-pages-mojo-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */)
}

